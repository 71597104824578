import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

export default ({ data, location }) => {
  const post = data.markdownRemark;
  // console.log(post);
  return (
    <Layout location={location}>
      {/* <h1>{post.frontmatter.title}</h1> */}
      <div
        className="card p-3"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </Layout>
  );
};

export const query = graphql`
  query AdePageQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
      }
    }
  }
`;
